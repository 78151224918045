.wrapper {
  margin-bottom: var(--spacing-10);
  width: 100%;
}

.heading {
  margin-bottom: var(--spacing-4);

  @media (--tablet-l) {
    font-size: 32px;
    margin-bottom: var(--spacing-8);
  }

  @media (--desktop) {
    margin-bottom: var(--spacing-10);
  }

  [data-page-theme='heywatt'] & {
    color: var(--color-secondary-700);
  }
}

.description {
  margin-bottom: var(--spacing-6);
}

.tabs {
  width: 100%;

  & [role='tablist'] {
    display: flex;
    flex-direction: row;

    background: none;
    width: 100vw;
    max-width: min(100vw - 900px);
    overflow: auto;
    padding: 1px;
    margin: -1px;
    position: relative;
    left: -19px;

    /* hide scrollbars */
    scrollbar-width: none;
    -ms-overflow-style: none;

    scroll-padding-left: 19px;
    padding-left: 19px;

    & button {
      height: 48px;
      background: none;
      box-shadow: none;
      border: 1px solid transparent;
      outline: 1px solid var(--Colour-Neutral-300, #b5b5b5);

      padding: 0 var(--spacing-3);
      white-space: nowrap;

      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      transition: 0.2s;

      &[aria-selected='true'] {
        position: relative;
        border: 1px solid var(--Button-Primary-Default, #164ca3);
        outline: 1px solid var(--Button-Primary-Default, #164ca3);
        background: var(--Surface-Primary, #f1f4fa);
      }
    }
  }
}

.tabContent {
  padding-top: var(--spacing-5);
}

.videoItems {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: var(--spacing-4);
}

.videoItem {
  --index: 0;
  scroll-snap-align: flex-start;
  max-width: calc(100vw - 48px);
  display: flex;
  flex-direction: column;
  transition: 0.2s ease;

  text-decoration: none;
  color: inherit;
  gap: var(--spacing-3);
  opacity: 1;
  will-change: transform, opacity;
  transition: 0.3s ease-in;
  transition-delay: calc(var(--index) * 0.1s + 0.01s);

  @starting-style {
    opacity: 0;
    /* transform: translateY(3px); */
  }

  &:hover {
    .playButton {
      transform: scale(1.1);
      background-color: var(--color-primary-600);
    }
  }
}

.imageContainer {
  position: relative;
  width: 400px;
  max-width: calc(100vw - 48px);
  aspect-ratio: 16/9;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButton {
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: var(--color-primary-500);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.playIcon {
  width: 24px;
  height: 24px;
  color: white;
}

.videoTitle {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
  margin: 0;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
